<!--
  @name: 餐饮购物车组件
  @author: zhengyao
  @date:2021-09-10
-->
<template>
  <div class="wrapper shopping-cart bg-white">
    <!-- 头部标题，清单打印清桌 -->
    <header class="header flex-between">
      <p class="top">
        待结账清单
      </p>
      <div>
        <a-button
          v-if="spuId && issue == 2"
          type="link"
          size="large"
          @click="onReprint"
          >重新打印</a-button
        >
        <a-button type="link" size="large" @click="onEmpty">清桌</a-button>
      </div>
    </header>
    <!-- 购物车列表-->
    <section class="main">
      <section class="text-c p-1x mt-1x" v-if="spuId">
        <a-radio-group
          v-model="issue"
          button-style="solid"
          @change="getFoodCartDetail"
        >
          <a-radio-button :value="1">未出单</a-radio-button>
          <a-radio-button :value="2">已出单</a-radio-button>
        </a-radio-group>
      </section>
      <section
        class=" flex-grow"
        :class="{ 'flex-col flex-center': !cartList.id }"
      >
        <!-- 空购物车 -->
        <a-empty
          :image="emptyCar"
          :image-style="{
            width: '238px',
            height: '238px',
            margin: '150px auto 50px'
          }"
          :description="false"
          v-if="!cartList.productItemList"
        />
        <!-- 购物车列表开始 -->
        <template v-if="cartList.productItemList">
          <div
            class="l_tree_container"
            v-for="product in cartList.productItemList"
            :key="product.specialId"
          >
            <div class="l_row">
              <div class="l_folder">
                <span class="text-2">{{ product.productName }}</span>
                <span class="handle align-start">
                  <a-button type="link" @click="delGoods(product, cartList.id)">
                    删除
                  </a-button>
                </span>
              </div>
            </div>
            <p class="dark">{{ product.sellingPrice | money }}</p>
            <!--套餐明细-->
            <ul class="l_tree" v-if="product.foodSetSubProduct">
              <li
                class="l_tree_branch"
                v-for="(lis, i) in packageGrouping(product.foodSetSubProduct)"
                :key="i"
              >
                <div class="l_row flex-start align-center">
                  <div class="l_tree_children_btn" @click="changePlus">+</div>
                  <div class="flex-between align-center flex-grow">
                    <p class="flex-grow ml-1x">{{ lis.groupName }}</p>
                    <p class="text-r">
                      <a-button
                        type="link"
                        @click="
                          addPurchase(
                            product,
                            packageGrouping(product.foodSetSubProduct),
                            lis
                          )
                        "
                        >选择</a-button
                      >
                    </p>
                  </div>
                </div>
                <ul class="l_tree">
                  <li
                    class="l_tree_branch"
                    v-for="som in lis.list"
                    :key="som.productId"
                  >
                    <div class="l_row">
                      <a-row>
                        <a-col :span="16">{{ som.productName }} </a-col>
                        <a-col :span="8">x{{ som.buyNum }}</a-col>
                      </a-row>
                      <p class="dark">
                        {{ som.settlementPrice | money }}
                      </p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <a-row class="mv-1x">
              <a-col :span="8" class="gray">{{
                product.totalSellingPrice | money
              }}</a-col>
              <a-col :span="8" class="font-xl">
                {{ product.allSettlementPrice | money }}
              </a-col>
              <div class="quantity-style">
                <div
                  class="minus-style"
                  @click="reduceNum(product, cartList.id)"
                >
                  <a-icon type="minus" />
                </div>
                <a-input
                  v-model="product.buyNum"
                  class="num-style"
                  @focus="buyNumCopy = product.buyNum"
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                  @blur="buyNumBlur(product, cartList.id)"
                ></a-input>
                <div class="plus-style" @click="addNum(product, cartList.id)">
                  <a-icon type="plus" />
                </div>
              </div>
            </a-row>
          </div>
        </template>
        <!-- 购物车列表结束-->
      </section>
    </section>
    <!-- 购物车底部按钮 -->
    <footer class="bottom">
      <div v-if="issue == 1" class="flex-between meals">
        <span>用餐方式</span>
        <a-radio-group v-model="meals">
          <a-radio :value="1">堂食</a-radio>
          <a-radio :value="2">打包</a-radio>
        </a-radio-group>
      </div>

      <div class="ph-2x">
        <div class="align-center flex-between">
          <span class="gray">共{{ cartList.ticketNum || 0 }}项 合计</span>
          <span class="font-xxl">{{
            (cartList.ticketNum ? cartList.totalPrice || 0 : 0) | money
          }}</span>
        </div>

        <div class="flex-between mt-x">
          <a-button
            v-permission="'appCateringMenuListWholeordercancellation'"
            class="flex-grow"
            size="large"
            shape="round"
            :disabled="!cartList.ticketNum"
            @click="cancelAll"
            radiu
            >整单取消</a-button
          >
          <a-button
            v-if="issue == 1 && spuId"
            class="flex-grow ml-2x"
            type="primary"
            size="large"
            shape="round"
            @click="checkOut('placeAnOrder')"
            :disabled="!cartList.ticketNum"
          >
            下单Enter
          </a-button>
          <a-button
            v-permission="'appCateringMenuListCheckout'"
            v-if="issue == 2 || !spuId"
            class="flex-grow  ml-2x"
            type="primary"
            size="large"
            shape="round"
            @click="checkOut('payment')"
            :disabled="!cartList.ticketNum"
          >
            <span class="mr-2x">结账<span class="f-key"></span>：</span>
            <span> {{ cartList.totalPrice || 0 | money }}</span>
          </a-button>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { onkeydown } from "@/utils/global";
import {
  foodCartDetail,
  moreProduct,
  emptyCart,
  settleAccounts,
  clearTable,
  printAgain
} from "@/api/cart";
export default {
  name: "ShoppingCart",
  data() {
    return {
      spuId: this.$route.query.spuId, //桌台spuId
      cateringCartId: sessionStorage.getItem("cateringCartId"), //购物车id
      buyNumCopy: 0, //复制购物车数量
      issue: 1, //1未出单 2已出单
      meals: 1, //1堂食 2打包
      title: "购物车",
      cartList: {}, //购物车列表
      emptyCar: require("../../assets/images/empty_car.png"),
      SHOP_MAX: 1000, // 和振山确认,每张票最多只能买1000张,不管后台配置的最大购买值
      isShowList: false,
      excessQuantity: false // 是否有票超出最大购买值
    };
  },
  created() {
    this.getFoodCartDetail();
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown);
  },
  computed: {
    ...mapGetters({
      shortcutKey: "setting/getshortcutKey"
    }),
    //处理套餐分组类目
    packageGrouping() {
      return function(array) {
        let obj = {},
          lis = [],
          groupNameList = [
            ...new Set(array.map(groupName => groupName.groupName))
          ];
        groupNameList.forEach(name => {
          if (!obj[name]) {
            obj[name] = 1;
            lis.push({
              groupName: name,
              list: array.filter(item => item.groupName == name)
            });
          }
        });
        return lis;
      };
    }
  },
  methods: {
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    // 根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 13:
          //回车
          if (this.issue == 1) {
            //下单
            this.checkOut("placeAnOrder");
          } else {
            //结账
            this.checkOut("payment");
          }
          break;
        default:
          break;
      }
    },
    //警告提示
    warning(text) {
      this.$message.warning(text);
    },
    //重新打印
    onReprint() {
      if (!this.cartList.productItemList) {
        return this.warning("没有产品哦");
      }
      printAgain({
        id: this.cateringCartId,
        tableSpuId: this.spuId ? this.spuId : null
      })
        .then(() => {})
        .catch(() => {});
    },
    //清桌
    onEmpty() {
      if (!this.spuId) {
        return this.warning("当前是快速点餐，没有桌台");
      }
      clearTable({ tableSpuId: this.spuId })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    //购物车详情
    getFoodCartDetail() {
      let { issue, spuId } = this;
      let cateringCartId = sessionStorage.getItem("cateringCartId");
      let data = {
        orderOut: issue //1未出单 2已出单
      };
      //没有购物车，不做查询
      if (!spuId && !cateringCartId) {
        return false;
      }
      if (spuId) {
        data["tableSpuId"] = spuId;
      } else if (cateringCartId) {
        data["id"] = cateringCartId;
      }
      foodCartDetail(data, { loading: false })
        .then(res => {
          if (Object.keys(res).length) {
            this.cartList = res;
            sessionStorage.setItem("cateringCartId", res.id);
            this.cateringCartId = res.id;
          } else {
            this.cartList = [];
            sessionStorage.removeItem("cateringCartId");
            this.cateringCartId = "";
          }
        })
        .catch(() => {});
    },

    //删除当前菜品
    delGoods(row, id) {
      let data = {
        id,
        productId: row.productId,
        spuId: row.spuId,
        buyNum: 0,
        specialId: row.specialId,
        tableSpuId: this.spuId ? this.spuId : null
      };
      this.updateCart(data);
    },
    //+号展开伸缩
    changePlus() {
      this.isShowList = !this.isShowList;
    },
    //选择加购
    addPurchase(product, foodSetSubProduct, row) {
      this.$parent.foodSetSubProduct = foodSetSubProduct;
      this.$parent.products = product;
      this.$parent.getPackage(product.spuId, row);
    },
    //数量减一
    reduceNum(row, cartId) {
      this.buyNumCopy = row.buyNum;
      this.excessQuantity = false;
      row.buyNum -= 1;
      this.updateByBuyNum(row, cartId);
    },
    //数量加一
    addNum(row, cartId) {
      this.buyNumCopy = row.buyNum;
      if (row.buyNum > this.SHOP_MAX) {
        this.excessQuantity = true;
        this.warning(`${row.productName}最多买${this.SHOP_MAX}张`);
        return;
      }
      this.excessQuantity = false;
      row.buyNum += 1;
      this.updateByBuyNum(row, cartId);
    },
    //手动改变数量失焦
    buyNumBlur(row, cartId) {
      this.excessQuantity = false;
      //数量发生改变才更新
      if (row.buyNum !== this.buyNumCopy) {
        this.updateByBuyNum(row, cartId);
      }
    },
    //整单取消
    cancelAll() {
      this.$confirm({
        title: "清空购物车",
        content: "确定要清空购物车吗？",
        onOk: () => {
          emptyCart({
            id: this.cateringCartId,
            orderOut: this.issue,
            tableSpuId: this.spuId ? this.spuId : null
          })
            .then(() => {
              this.getFoodCartDetail();
            })
            .catch(() => {});
        },
        onCancel() {}
      });
    },
    //监听数量变化
    updateByBuyNum(row, cartId) {
      let data = {
        tableSpuId: this.spuId ? this.spuId : null, //桌台id
        id: cartId, //购物车id
        productId: row.productId, // 产品id
        spuId: row.spuId, // 产品的spuId
        specialId: row.specialId,
        buyNum: row.buyNum //购买数量
      };

      this.updateCart(data, row);
    },
    //更新购物车
    updateCart(data, row = {}) {
      moreProduct(data)
        .then(() => {
          this.getFoodCartDetail();
        })
        .catch(() => {
          if (Object.keys(row).length) {
            row.buyNum = this.buyNumCopy;
          }
        });
    },
    //下单&&结账
    checkOut(type) {
      if (!this.cartList.ticketNum) {
        return this.$message.warning("没有商品");
      }
      settleAccounts({
        id: this.cartList.id,
        orderOut: this.issue,
        useType: this.meals,
        tableSpuId: this.spuId ? this.spuId : null
      })
        .then(() => {
          if (type == "placeAnOrder") {
            //下单
            this.issue = 2;
            this.getFoodCartDetail();
          } else if (type == "payment") {
            //结账
            //this.$store.commit("order/setCartList", res);
            this.$router.push({
              path: "/home/sell/OrderInfo",
              query: { format: "catering", tableSpuId: this.spuId }
            });
          }
        })
        .catch(() => {});
    },
    //回车结账
    keyDown(e) {
      if (
        (this.issue == 2 && this.cartList.totalPrice) ||
        (!this.spuId && this.cartList.totalPrice)
      ) {
        if (e.keyCode === 13) {
          this.checkOut("payment");
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

// 去掉了number的默认样式
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shopping-cart {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 364px;
  margin: 16px 16px 0 0;
  .header {
    padding: 13px 16px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    .top {
      font-size: 24px;
    }
  }
  .main {
    /*height: 100%;*/
    padding: 0 16px 8px;
    font-size: 16px;
    /deep/.ant-btn-link {
      padding: 0 0 0 16px;
      font-size: 18px;
      line-height: 1;
    }
  }
}
.l_tree_container {
  width: 100%;
  position: relative;
  padding-top: 16px;
  padding-bottom: 12px;
  border-bottom: 3px solid #eff0f3;
  &:last-child {
    border: none;
  }
}
.l_tree {
  width: 100%;
  height: 100%;
  padding-left: 14px;
  padding-top: 20px;
}
.l_tree_branch {
  position: relative;
  display: block;
  width: 100%;
  padding: 6px 0 12px 12px;
}
.l_folder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.l_tree_children_btn {
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #999;
  color: #999;
  outline: none;
  cursor: pointer;
}
.l_row {
  position: relative;
  .l_tree_children_btn + .l_folder {
    position: absolute;
    top: -3px;
    left: 30px;
  }
}
ul.l_tree:before {
  content: "";
  border-left: 1px dashed #999999;
  height: calc(100%);
  position: absolute;
  top: 0;
  left: 10px;
}

.l_tree .l_tree_branch:last-child::before {
  content: "";
  width: 4px;
  height: calc(100% - 16px);
  display: block;
  background-color: #ffffff;
  position: absolute;
  bottom: 0;
  left: -6px;
}

.l_tree,
.l_tree_branch {
  position: relative;
}

.l_tree_branch::after {
  content: "";
  width: 12px;
  height: 0;
  border-bottom: 1px dashed #999999;
  position: absolute;
  right: calc(100% - 9px);
  top: 16px;
}
.text-2 {
  width: 100%;
}
.handle {
  width: 60px;
}
.quantity {
  width: 50px;
  text-align: center;
  border-left: none;
  border-right: none;
}

.bottom {
  padding-bottom: 12px;
  flex-shrink: 0;
  z-index: 1;
  box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.12);
  .meals {
    padding: 10px;
    border-bottom: 1px solid @border;
  }
}

@media screen and (max-width: 1366px) {
  .f-key {
    display: none;
  }
}
</style>
