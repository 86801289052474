<template>
  <div class="footer">
    <div class="flex-start align-center">
      <member-menu @click="goToMember"></member-menu>
      <div
        v-if="type == 2"
        class="menu"
        @click="$router.push('/catering/TakeNumber')"
      >
        取号排队
        <span class="f-key" v-if="shortcutKey">(F2)</span>
      </div>
      <div
        v-if="type == 1"
        class="menu"
        @click="$router.push('/catering/CallNumber')"
      >
        叫号
        <span class="f-key" v-if="shortcutKey">(F3)</span>
      </div>
    </div>
    <div class="menu back-menu" @click="$router.back()">
      返回
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import MemberMenu from "../../../../components/member/MemberMenu.vue";
import { onkeydown } from "@/utils/global";

export default {
  name: "takeNumber-footer",
  components: { MemberMenu },
  props: {
    // 取号排队（1）页面的footer，还是叫号（2）页面的footer
    type: {
      type: [Number, String],
      default: 1
    },
    // 底部会员点击按钮，点击进入会员详情后，关闭会员详情时返回到哪个页面的配置
    // 默认，返回取号排队页面
    backurl: {
      type: String,
      default: "catering-takeNumber"
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      shortcutKey: "setting/getshortcutKey"
    })
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  },
  methods: {
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    // 根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 112:
          // console.log("按了F1");
          this.$router.push({
            path: "/catering/member",
            query: { backurl: this.backurl }
          });
          break;

        case 113:
          // console.log("按了F2");
          this.$router.push({ path: "/catering/TakeNumber" });
          break;

        case 114:
          // console.log("按了F3");
          this.$router.push({ path: "/catering/CallNumber" });
          break;

        default:
          break;
      }
    },
    goToMember() {
      this.$router.push({
        path: "/catering/member",
        query: { backurl: this.backurl }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  .menu {
    position: relative;
    width: 160px;
    padding: 8px 0;
    border: 1px solid @primary;
    text-align: center;
    color: @primary;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 12px;
    .iconfont {
      font-size: 22px;
      position: absolute;
      top: -15px;
      right: -13px;
    }
    &.action {
      color: #ffffff;
      background-color: @primary;
    }
    &.disabled {
      border-color: @disabled;
      color: @disabled;
      cursor: not-allowed;
      background: #f5f5f5;
    }
  }
}
</style>
