<template>
  <!-- 叫号 -->
  <div class="wrapper pb-2x flex-start flex-col">
    <panel-head :close="true" title="叫号"></panel-head>
    <div class="container mt-2x flex-grow flex-start">
      <div class="left-call-box">
        <div
          v-for="(item, index) in list"
          :key="item.id"
          :index="index"
          :class="{ active: index === current }"
          @click="callItem(index, item)"
        >
          <span> {{ item.name }}</span>
          <span>({{ JSON.parse(item.categoryData).recommendUseNum }} 人) </span>
        </div>
        <!--分页-->
        <div
          class="flex-end mt-3x"
          v-show="categoryTotal > 0"
          style="background-color:white;border:none;"
        >
          <a-pagination
            v-model="categoryPage"
            :default-page-size="categorySize"
            :total="categoryTotal"
            @change="typeCurrentChange"
            @showSizeChange="typeSizeChange"
          />
        </div>
      </div>

      <!-- 表格内容 -->
      <div class="right-call-box">
        <a-table
          :columns="columns"
          :data-source="numberList"
          :pagination="false"
          :expandRowByClick="true"
          :indentSize="20"
          :rowKey="(record, index) => index"
        >
          <div slot="time" slot-scope="row">
            {{ row | date("YYYY-MM-DD hh:mm:ss") }}
          </div>
          <template slot="action" slot-scope="row">
            <a-button type="link p-0" @click="call(row)"
              >叫号
              <p>
                (已叫 <b>{{ row.callNum }}</b> 次)
              </p>
            </a-button>
            <a-button type="link pl-3x" @click="assignSeat(row)"
              >派座
            </a-button>
            <a-button type="link pl-3x" @click="passNumber(row)"
              >过号
            </a-button>
          </template>
        </a-table>
        <!--分页-->
        <div
          class="flex-end mt-3x"
          v-show="total > 0"
          style="background-color:white"
        >
          <a-pagination
            v-model="page"
            :default-page-size="size"
            :total="total"
            :page-size-options="['10', '20', '50', '100']"
            show-size-changer
            @change="currentChange"
            @showSizeChange="sizeChange"
          />
        </div>
      </div>
    </div>
    <PublicFooter :type="2" backurl="catering-callNumber"></PublicFooter>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import PublicFooter from "./components/Footer.vue";
import {
  callNumberCategoryPage,
  tableTypeRowList,
  useQueueInfoCallNumber,
  allocationPassNumber
} from "@/api/product";
const columns = [
  {
    title: "排队号",
    dataIndex: "queueCode",
    key: "queueCode"
  },
  {
    title: "取号时间",
    dataIndex: "createTime",
    key: "createTime",
    scopedSlots: { customRender: "time" }
  },

  {
    title: "等待时长(分钟)",
    key: "waitingTime",
    dataIndex: "waitingTime"
  },

  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];

export default {
  name: "CallNumber",
  components: { PanelHead, PublicFooter },
  data() {
    return {
      list: [], //桌台类型
      columns, // 桌台排队列表参数
      numberList: [], //桌台排队列表数据
      current: 0, //选中桌台的index值，默认第一个选中
      selectQueueTarget: "", //选中的桌台id
      total: 0,
      page: 1,
      size: 10,
      categoryPage: 1,
      categorySize: 10,
      categoryTotal: 0
    };
  },
  created() {
    //桌台类型列表
    this.getTypeList();
  },
  methods: {
    //桌台类型列表
    getTypeList() {
      callNumberCategoryPage({
        merchantId: sessionStorage.getItem("saleMerchantId"),
        page: this.categoryPage,
        size: this.categorySize
      })
        .then(res => {
          this.list = res.records;
          this.categoryTotal = res.total;
          //默认请求第一条桌台的数据
          this.getList(res.records[0].id);
          this.selectQueueTarget = res.records[0].id;
        })
        .catch(() => {});
    },
    //获取桌台排队列表
    getList(id) {
      tableTypeRowList({
        page: this.page,
        size: this.size,
        queueTarget: id,
        merchantId: sessionStorage.getItem("saleMerchantId")
      })
        .then(res => {
          this.numberList = [];
          this.numberList = res.records;
          this.total = res.total;
        })
        .catch(() => {});
    },
    // 桌台排队列表页码改变
    currentChange(p) {
      this.page = p;
      this.getList(this.selectQueueTarget);
    },
    // 桌台排队列表条数改变
    sizeChange(p, s) {
      this.page = 1;
      this.size = s;
      this.getList(this.selectQueueTarget);
    },
    // 桌台类型列表页码改变
    typeCurrentChange(p) {
      this.categoryPage = p;
      this.getTypeList();
    },
    // 桌台类型列表条数改变
    typeSizeChange(p, s) {
      this.categoryPage = 1;
      this.categorySize = s;
      this.getTypeList();
    },
    //点桌台类型
    callItem(index, item) {
      this.current = index;
      this.selectQueueTarget = item.id;
      this.getList(item.id);
    },
    //叫号(调用语音功能未做)
    call(row) {
      useQueueInfoCallNumber({
        id: row.id,
        callNum: row.callNum
      })
        .then(() => {
          this.getList(this.selectQueueTarget);
        })
        .catch(() => {});
    },
    //派座
    assignSeat(row) {
      let that = this;
      this.$confirm({
        title: "派座管理",
        content: "是否确认派座?",
        onOk() {
          allocationPassNumber({
            id: row.id,
            queueStatus: 2
          })
            .then(() => {
              that.$router.push({
                path: "/catering",
                query: {
                  id: sessionStorage.getItem("saleMerchantId")
                }
              });
            })
            .catch(() => {});
        },
        onCancel() {}
      });
    },
    //过号
    passNumber(row) {
      let that = this;
      this.$confirm({
        title: "过号管理",
        content: "是否确认过号?",
        onOk() {
          allocationPassNumber({
            id: row.id,
            queueStatus: 3
          })
            .then(() => {
              that.getList(that.selectQueueTarget);
            })
            .catch(() => {});
        },
        onCancel() {}
      });
    }
  }
};
</script>

<style lang="less" scoped>
.left-call-box {
  width: 200px;
  border-radius: 4px;
  div {
    border-left: 1px solid #4182fc;
    border-right: 1px solid #4182fc;
    border-top: 1px solid #4182fc;
    text-align: center;
    padding: 15px 0;
  }
  div:nth-last-child(2) {
    border-bottom: 1px solid #4182fc;
  }

  .active {
    background-color: #4182fc;
    color: white;
    border-bottom: none;
  }
}
.right-call-box {
  width: calc(100vh - 200px);
  flex-grow: 1;
  margin-left: 20px;
}
</style>
