<!-- 桌台点餐 -->
<template>
  <div>
    <!--餐饮内容-->
    <div
      class="table-order wrapper flex-between flex-col relative"
      v-show="!showMember"
    >
      <div class="header flex-grow">
        <!-- 左侧桌台 -->
        <div
          class="left"
          :class="{
            'tips-flex': regionList.length <= 0
          }"
        >
          <template v-if="regionList.length > 0">
            <div class="list" v-for="(item, index) in regionList" :key="index">
              <p class="font-xl gray mb-2x" v-if="curIndex == 0">
                {{ item.areaName }}
              </p>
              <ul class="flex-start flex-wrap">
                <li
                  class="table-card mr-2x mb-2x pointer relative flex-center"
                  :class="{
                    'active-li':
                      (subItem.foodTime && subItem.totalPrice) ||
                      subItem.whetherSaleable == 0
                  }"
                  v-for="(subItem, subIndex) in item.list"
                  :key="subIndex"
                  @click="orderClick(subItem)"
                >
                  <span class="font-xl primary s1">{{ subItem.name }}</span>
                  <span class="gray font-xl s2">{{
                    subItem.categoryName
                  }}</span>
                  <a-icon
                    v-if="
                      !subItem.foodTime &&
                        !subItem.totalPrice &&
                        subItem.whetherSaleable != 0
                    "
                    class="primary font-xxl"
                    type="plus"
                  />
                  <span
                    v-if="
                      subItem.whetherSaleable == 0 &&
                        !subItem.foodTime &&
                        !subItem.totalPrice
                    "
                    class="font-xl white"
                  >
                    未清桌
                  </span>
                  <span class="s4 font-xxxxl" v-if="subItem.totalPrice">
                    {{ subItem.totalPrice | money }}
                  </span>
                  <span class="s3 font-xl white" v-if="subItem.foodTime">
                    {{ subItem.foodTime | date("YYYY-MM-DD hh:mm") }}
                  </span>
                </li>
              </ul>
            </div>
          </template>
          <div v-else class="text-c gray font-xxxxl">
            <a-icon type="question-circle" theme="filled" />
            <p>本区域尚未添加桌台</p>
          </div>
        </div>
        <!-- 右侧楼层菜单 -->
        <div class="right text-c bd round flex-shrink" v-if="menu.length > 1">
          <ul>
            <li
              class="primary"
              :class="{ cur: curIndex == index }"
              v-for="(item, index) in menu"
              :key="index"
              @click="menuClick(index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="footer">
        <member-menu
          :vip="'appCateringIndexVip'"
          @click="showMember = true"
        ></member-menu>
        <div
          class="menu"
          v-permission="'appCateringIndexNumberqueuing'"
          @click="takeNumber"
        >
          取号排队
          <span class="f-key" v-if="shortcutKey">(F2)</span>
        </div>
        <div
          v-permission="'appCateringIndexCall'"
          class="menu"
          @click="callNumber"
        >
          叫号
          <span class="f-key" v-if="shortcutKey">(F3)</span>
        </div>
      </div>
    </div>

    <!-- 会员详情 -->
    <member-detail
      class="flex-grow"
      v-show="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { productFrontDeskorderfood } from "../../../api/search";
import { merchantAllCart } from "../../../api/cart";
import MemberMenu from "../../../components/member/MemberMenu.vue";
import { onkeydown } from "@/utils/global";
import MemberDetail from "../../home/member/MemberDetail";

export default {
  name: "TableOrder",
  components: { MemberMenu, MemberDetail },
  props: {},
  computed: {
    ...mapGetters({
      shortcutKey: "setting/getshortcutKey"
    })
  },
  data() {
    return {
      showMember: false,
      menu: [{ areaId: 1, name: "全部" }],
      curIndex: 0,
      customData: [],
      regionList: []
    };
  },
  created() {
    // 获取已点餐的桌台
    this.getCartData();
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    // 根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 112:
          // console.log("按了F1");
          // this.$router.push({ path: "/catering/member" });
          this.showMember = true;
          break;

        case 113:
          // console.log("按了F2");
          this.takeNumber();
          break;

        case 114:
          // console.log("按了F3");
          this.callNumber();
          break;

        default:
          break;
      }
    },
    // 获取已点餐的桌台
    getCartData() {
      merchantAllCart()
        .then(res => {
          this.getDingData(res);
        })
        .catch(() => {});
    },
    // 获取桌台数据
    getDingData(arr) {
      productFrontDeskorderfood()
        .then(res => {
          // 从数据中，抽取出右侧楼层菜单数据
          if (res && res.length) {
            res.forEach(item => {
              this.menu.push({
                areaId: item.areaId,
                name: item.areaName
              });
              // 处理，哪些桌台是已经用餐中的
              if (item.list && item.list.length) {
                item.list.forEach(subItem => {
                  arr.forEach(subItem2 => {
                    if (subItem.spuId == subItem2.tableSpuId) {
                      subItem.foodTime = subItem2.foodTime;
                      subItem.totalPrice = subItem2.totalPrice;
                    }
                  });
                });
              }
            });
            this.regionList = res;
            this.customData = res;
          }
        })
        .catch(() => {});
    },
    // 右侧楼层菜单点击
    menuClick(index) {
      this.curIndex = index;
      if (index == 0) {
        this.regionList = this.customData;
        return;
      }
      this.regionList = [];
      this.regionList = this.customData[index - 1]
        ? [this.customData[index - 1]]
        : [];
    },
    // 左侧桌台点击
    orderClick(item) {
      // if (item.foodTime || item.totalPrice) {
      //   this.$message.warning("该桌台已经开桌，请选择其它桌台");
      //   return false;
      // }
      this.$router.push({
        path: "/catering/menuList",
        query: { id: item.id, spuId: item.spuId }
      });
      // 设置左侧菜单：快速点餐 高亮
      this.$store.commit("common/setCurrentLeftMenuKey", "catering:menuList");
      this.setEndOfPayConfig({ backUrl: "/catering", logoutMember: true });
    },
    // 取号排队
    takeNumber() {
      this.$router.push({ path: "/catering/TakeNumber" });
    },
    // 叫号
    callNumber() {
      this.$router.push({ path: "/catering/CallNumber" });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/less/custom.less";
.slide {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  overflow-x: hidden;
}
.header {
  align-items: flex-start !important;
  .tips-flex {
    display: flex;
    justify-content: center;
    padding-top: 280px;
  }
  .left {
    flex: 1;
    .slide;
    .table-card {
      width: 200px;
      height: 200px;
      border-radius: @2x;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      background-color: #f4f9fc;
      align-items: center;
      overflow: hidden;
      &.active-li {
        background-color: @primary;
        .s1,
        .s2,
        .s3,
        .s4 {
          color: #fff;
        }
      }
      &:hover {
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
      }
      .s1,
      .s2 {
        position: absolute;
        top: 10px;
        max-width: 80px;
      }
      .s1 {
        left: 20px;
      }
      .s2 {
        right: 20px;
      }
      .s3 {
        position: absolute;
        bottom: 10px;
      }
    }
  }
  .right {
    width: 160px;
    height: 100%;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    .slide;
    li {
      width: 100%;
      height: 80px;
      line-height: 80px;
      &:first-child {
        &.cur {
          border-top-left-radius: @1x;
          border-top-right-radius: @1x;
        }
      }
    }
    .cur {
      background-color: @primary;
      color: #fff;
    }
  }
}
.footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  .menu {
    position: relative;
    width: 13%;
    padding: 8px 0;
    border: 1px solid @primary;
    text-align: center;
    color: @primary;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 12px;
    .iconfont {
      font-size: 22px;
      position: absolute;
      top: -15px;
      right: -13px;
    }
    &.action {
      color: #ffffff;
      background-color: @primary;
    }
    &.disabled {
      border-color: @disabled;
      color: @disabled;
      cursor: not-allowed;
      background: #f5f5f5;
    }
  }
}
</style>
