<template>
  <div class="wrapper flex-between flex-col">
    <panel-head title="取餐"></panel-head>
    <div class="main bg-white">
      <div v-for="(item, index) in pickData" :key="index" class="mb-4x bd ">
        <div class="topBox">
          <div>取餐号：{{ item.auxiliaryId }}</div>
          <div>用餐方式：{{ item.diningWay }}</div>
          <div>
            用餐时间：{{ item.diningTime | date("YYYY-MM-DD hh:mm:ss") }}
          </div>
          <div>订单号：{{ item.id }}</div>
          <div>支付时间：{{ item.payTime | date("YYYY-MM-DD hh:mm:ss") }}</div>
          <div>
            购买人：{{ item.purchaserMemberName }}
            <span v-if="item.purchaserMemberPhone">/</span>
            {{ item.purchaserMemberPhone }}
          </div>
        </div>
        <!-- 表格内容 -->
        <a-table
          :columns="columns"
          :data-source="item.dishesDetails"
          :row-selection="rowSelection"
          :pagination="false"
          :expandRowByClick="true"
          :indentSize="20"
          :scroll="{ y: 400 }"
          :rowKey="record => record.id"
        >
          <div slot="money" slot-scope="row">
            {{ row | money }}
          </div>
          <div slot="time" slot-scope="row">
            {{ row | date("YYYY-MM-DD hh:mm:ss") }}
          </div>
          <template slot="action" slot-scope="row">
            <a-button
              type="link p-0"
              @click="takeGoods(row)"
              v-if="row.statusText != '已取餐'"
              >核销取餐</a-button
            >
          </template>
        </a-table>
      </div>
    </div>
    <!-- 底部 -->
    <div class="flex-end ticket-bottom" slot="footer">
      <a-button class="payment-btn" @click="AllTakeGoods" v-if="isShowAllBtn">
        取餐
      </a-button>
      <a-button class="cancel-btn" @click="cancel">
        取消
      </a-button>
    </div>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import { orderTakefood } from "../../../api/search";
import { tickPickup } from "@/api/order";
const columns = [
  {
    title: "产品名称",
    dataIndex: "productName",
    key: "productName"
  },
  {
    title: "规格",
    dataIndex: "specification",
    key: "specification"
  },
  {
    title: "取餐状态",
    dataIndex: "statusText",
    key: "statusText"
  },
  {
    title: "结算金额",
    key: "receivedAmount",
    dataIndex: "receivedAmount",
    scopedSlots: { customRender: "money" }
  },
  {
    title: "取餐时间",
    key: "firstWriteOffTime",
    dataIndex: "firstWriteOffTime",
    scopedSlots: { customRender: "time" }
  },
  {
    title: "操作人",
    key: "salespersonName",
    dataIndex: "salespersonName"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
let selectId = []; //全选的ID
const rowSelection = {
  onChange: selectedRowKeys => {
    selectId = Array.from(selectedRowKeys);
  },
  //当已取餐时，checkbox禁用掉，status: 6 为已取餐,接口无返回status,用statusText
  getCheckboxProps: record => {
    return {
      props: {
        disabled: record.statusText === "已取餐"
      }
    };
  }
};

export default {
  components: { PanelHead },
  name: "TakeFoodList",
  data() {
    return {
      numbers: "", //票号
      pickData: [], //接口返回的数组
      columns, //表格字段
      rowSelection,
      selectId, //多选选中id
      isShowAllBtn: false //是否显示批量取餐按钮
    };
  },
  created() {
    this.numbers = this.$route.query.numbers;
    this.getData();
  },
  methods: {
    getData() {
      orderTakefood({
        keyword: this.numbers
      })
        .then(res => {
          if (res.length > 0) {
            this.pickData = res;
            for (let i = 0; i < res.length; i++) {
              let filterData = res[i].dishesDetails.find(item => {
                return item.statusText === "未取餐";
              });
              //如果存在未聚餐的数据，显示批量取餐的按钮
              if (filterData) {
                this.isShowAllBtn = true;
              }
            }
          } else {
            this.$message.warning("查无可取餐记录");
          }
        })
        .catch(() => {});
    },
    //取餐
    takeGoods(row) {
      let idList = row.id.split(",");
      this.$confirm({
        title: "确定要核销取餐吗?",
        okText: "确定",
        onOk: () => {
          tickPickup({
            idList: idList
          }).then(() => {
            this.$message.success("操作成功");
            this.getData();
          });
        }
      });
    },
    //批量取餐
    AllTakeGoods() {
      if (selectId.length > 0) {
        this.$confirm({
          title: "确定要核销取餐吗?",
          okText: "确定",
          onOk: () => {
            tickPickup({
              idList: selectId
            }).then(() => {
              this.$message.success("操作成功");
              this.getData();
            });
          }
        });
      } else {
        this.$message.info("请勾选要核销的菜品");
      }
    },
    //取消，返回上一页
    cancel() {
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
/*中部*/
.main {
  padding: 16px;
  margin: 10px 0;
  height: 530px;
  .topBox {
    background-color: rgba(242, 242, 242, 1);
    align-items: center;
    padding: 10px 16px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 3px;
    div {
      float: left;
      padding: 5px 10px;
      font-size: 16px;
      width: 33.33%;
    }
  }
}

//底部
.ticket-bottom {
  padding: 20px;
  background-color: white;
  box-shadow: 0px 0px 9px 0px rgba(116, 116, 116, 0.22);
  border-radius: 5px;
  // 底部通用按钮
  .cancel-btn,
  .payment-btn {
    width: 240px;
    height: 44px;
    line-height: 44px;
    border-radius: 22px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
  }
  // 取消按钮
  .cancel-btn {
    border: 1px solid #4182fc;
    color: #4182fc;
    margin-left: 30px;
  }
  // 取货按钮
  .payment-btn {
    background: linear-gradient(181deg, #44b8fb, #4d8afb);
    box-shadow: 3px 0px 9px 0px rgba(116, 116, 116, 0.26);
    color: white;
    padding: 0 20px;
  }
}
</style>
