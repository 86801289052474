/**
 *@name: 调取硬件方法
 *@date: 2021-11-13
 *@author: 易远胜
 *@params: { Function } success 成功回调
 *@params: { Function } fail 失败回调
 */

// 被动扫码方法
export function scanCode(success, fail = () => {}) {
  try {
    // （传参数为1那么回调的js方法是payScan，0是scanCode方法）
    window.test.optScan(0);
    window.scanCode = res => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    console.warn("调取被动扫码方法失败");
    return false;
  }
}

// 主动扫码方法
export function scanQRCode(success, fail = () => {}) {
  try {
    window.test.scanQRCode();
    window.scanCode = res => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    console.warn("调取主动扫码方法失败");
    return false;
  }
}

// 取消扫码监听扫码方法
export function clearScanCode(fail = () => {}) {
  try {
    window.scanCode = () => {};
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 语音播报节流
let speakTimer = null;
// 语音提示
export function speak(message, fail = () => {}) {
  if (window.test) {
    if (speakTimer) {
      clearTimeout(speakTimer);
    }
    speakTimer = setTimeout(() => {
      window.test.speak(message);
      clearTimeout(speakTimer);
      speakTimer = null;
    }, 500);
    return true;
  } else {
    fail("调取语音播放方法失败");
    console.warn("调取语音播放方法失败");
    return false;
  }
}
// 读取身份证 信息
export function readIdCard(success = () => {}, fail = () => {}) {
  try {
    window.readIdCard = res => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 取消身份证读取监听方法
export function clearReadIdCard(fail = () => {}) {
  try {
    window.readIdCard = () => {};
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}

// 打印门票
export function printTicket(jsonStr, fail = () => {}) {
  try {
    window.test.printTicket(jsonStr);
    return true;
  } catch (error) {
    fail(error);
    console.warn("调取门票打印方法失败");
    return false;
  }
}

// 打印小票（餐饮）
export function printSmallTicket(jsonStr) {
  try {
    window.test.printSmallTicket(jsonStr);
    return true;
  } catch (error) {
    console.warn("调取小票打印方法失败");
    return false;
  }
}

/**
 * 打印零售小票
 * @param jsonStr
 * @returns { Boolean } // 如果成功打印，返回真
 */
export function printRetail(jsonStr) {
  try {
    window.test.printRetail(jsonStr);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

/**
 * 获取摄像头返回的图像数据
 * @param fn 绑定一个函数接收拍摄结果
 */
export function getCameraPicture(fn) {
  try {
    window.getPicture = function(res) {
      fn(res);
    };
    return true;
  } catch (error) {
    console.warn("获取摄像头数据失败");
    return false;
  }
}
/**
 * 开启摄像头
 */
export function openCamera(success = () => {}, fail = () => {}) {
  try {
    window.test.openCamera();
    success();
    return true;
  } catch (error) {
    fail(error);
    console.warn("调取摄像头方法失败");
    return false;
  }
}

/**
 * 监听打印进度
 */
export function printProgress(success, fail = () => {}) {
  try {
    window.printProgress = res => {
      success(res);
    };
    return true;
  } catch (error) {
    fail(error);
    return false;
  }
}
// 取消打印进度监听
export function clearPrintProgress() {
  try {
    window.printProgress = () => {};
    return true;
  } catch (error) {
    return false;
  }
}
