<template>
  <!-- 取号排队 -->
  <div class="wrapper pb-2x flex-start flex-col">
    <panel-head :close="true" title="排队取号"></panel-head>
    <div class="container mt-x flex-grow" style="position:relative;">
      <!-- 桌台类型列表 -->
      <div class="catering-type-box flex-start flex-wrap">
        <div
          v-for="(item, index) in list"
          :key="index"
          :index="index"
          @click="getData(item, index)"
          class="catering-type"
          :class="{ active: current === index }"
        >
          <div>
            <span v-if="item.queueTargetName">
              {{ item.queueTargetName }}：</span
            >
            <span v-if="item.categoryData"
              >({{ JSON.parse(item.categoryData).recommendUseNum }} 人)</span
            >
          </div>
          <div class="mt-x">
            当前排队：<span class="bold">{{ item.stillNeedToWait }}</span>
          </div>
        </div>
        <!-- 无数据时 -->
        <div v-if="list.length === 0" class="font-xl">
          此商家尚未添加桌台类型，请前往后台系统进行添加
        </div>
      </div>
      <div class="text-c mt-3x take-btn-box" v-if="list.length > 0">
        <a-button type="primary" class="take-btn" @click="takeBtn"
          >取号</a-button
        >
      </div>
      <!-- 桌台最近十条数据 -->
      <div class="cater-list-box" v-show="isShow">
        <div
          class="cater-list-item shadow"
          v-for="(temp, idx) in caterList"
          :key="temp.id"
          :idx="idx"
        >
          <div class="flex-between">
            <div class="flex-start">
              <a-icon type="check-circle" class="font-xl primary" />
              <p class="ml-2x bold font-xl cater-num">{{ temp.queueCode }}</p>
            </div>
            <a-icon type="close" class="font-xl" @click="closeItem(idx)" />
          </div>
          <div>桌台类型：{{ temp.queueTargetName }}</div>
          <div>
            还需等待：<b class="red mr-x">{{ temp.stillNeedToWait }}</b
            >桌
          </div>
        </div>
      </div>
    </div>
    <PublicFooter></PublicFooter>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import PublicFooter from "./components/Footer.vue";
import {
  tableTypeList,
  tableNumberQueue,
  tableNumberList
} from "@/api/product";

import { cloudPrintTakeANumber } from "@/api/iot";

export default {
  name: "TakeNumber",
  components: { PanelHead, PublicFooter },
  data() {
    return {
      current: "", //选中的桌台索引
      selectItem: {}, //选中的桌台相关值
      list: [], //桌台类型列表
      caterList: [], //桌台排队列表
      isShow: false, //是否展示
      queueTargetName: "", //桌台类型名称
      queueCode: "", //排队号
      waitingNum: "" //前面等待数
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //获取桌台类型列表
    getList() {
      tableTypeList({
        merchantId: sessionStorage.getItem("saleMerchantId")
      })
        .then(res => {
          this.list = res;
        })
        .catch(() => {});
    },
    //点击桌台号
    getData(item, index) {
      this.current = index;
      this.selectItem = item;
    },
    //取号，打印叫号单（连接打印机功能未做）
    takeBtn() {
      if (this.selectItem.queueTarget && this.selectItem.queueTargetName) {
        tableNumberQueue({
          queueTarget: this.selectItem.queueTarget,
          queueTargetName: this.selectItem.queueTargetName,
          merchantId: sessionStorage.getItem("saleMerchantId")
        })
          .then(() => {
            //获取最近十条数据
            tableNumberList({
              merchantId: sessionStorage.getItem("saleMerchantId")
            })
              .then(res => {
                this.isShow = true;
                this.caterList = res;
                this.queueTargetName = res[0].queueTargetName;
                this.queueCode = res[0].queueCode;
                this.waitingNum = res[0].stillNeedToWait;
                this.current = "";
                this.selectItem = {};
                this.connectPrint();
                this.printData = this.getList();
              })
              .catch(() => {});
          })
          .catch(() => {});
      } else {
        this.$message.warning("请选择桌台");
      }
    },
    //取号连接打印机
    connectPrint() {
      cloudPrintTakeANumber({
        queueTargetName: this.queueTargetName,
        queueCode: this.queueCode,
        waitingNum: this.waitingNum
      })
        .then(() => {})
        .catch(() => {});
    },
    //移除当前数据
    closeItem(idx) {
      this.caterList.splice(idx, 1);
    }
  }
};
</script>

<style lang="less" scoped>
.catering-type-box {
  width: 600px;
  margin: 0 auto;
  padding: 15px 0;
  max-height: 640px;
  overflow-y: auto;
  .catering-type {
    border: 1px solid #4182fc;
    border-radius: 5px;
    text-align: center;
    padding: 15px 0;
    width: 160px;
    margin: 15px;
    cursor: pointer;
  }
  .active {
    background-color: #4182fc;
    color: white;
    border: none;
  }
}

.take-btn {
  width: 600px;
  height: 50px;
  font-size: 18px;
}

.cater-list-box {
  position: absolute;
  right: 10px;
  top: 10px;
  max-height: 640px;
  overflow-y: auto;
  .cater-list-item {
    width: 260px;
    padding: 10px;
    margin: 0 5px 15px 5px;
    border-radius: 3px;
    border: 1px solid #e5e5e5;

    .cater-num {
      position: relative;
      bottom: 5px;
    }
    div {
      padding: 3px 0;
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1380px) {
  .catering-type-box {
    margin: 0;
  }

  .take-btn-box {
    text-align: left !important;
  }
}
</style>
