var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showMember),expression:"!showMember"}],staticClass:"table-order wrapper flex-between flex-col relative"},[_c('div',{staticClass:"header flex-grow"},[_c('div',{staticClass:"left",class:{
          'tips-flex': _vm.regionList.length <= 0
        }},[(_vm.regionList.length > 0)?_vm._l((_vm.regionList),function(item,index){return _c('div',{key:index,staticClass:"list"},[(_vm.curIndex == 0)?_c('p',{staticClass:"font-xl gray mb-2x"},[_vm._v(" "+_vm._s(item.areaName)+" ")]):_vm._e(),_c('ul',{staticClass:"flex-start flex-wrap"},_vm._l((item.list),function(subItem,subIndex){return _c('li',{key:subIndex,staticClass:"table-card mr-2x mb-2x pointer relative flex-center",class:{
                  'active-li':
                    (subItem.foodTime && subItem.totalPrice) ||
                    subItem.whetherSaleable == 0
                },on:{"click":function($event){return _vm.orderClick(subItem)}}},[_c('span',{staticClass:"font-xl primary s1"},[_vm._v(_vm._s(subItem.name))]),_c('span',{staticClass:"gray font-xl s2"},[_vm._v(_vm._s(subItem.categoryName))]),(
                    !subItem.foodTime &&
                      !subItem.totalPrice &&
                      subItem.whetherSaleable != 0
                  )?_c('a-icon',{staticClass:"primary font-xxl",attrs:{"type":"plus"}}):_vm._e(),(
                    subItem.whetherSaleable == 0 &&
                      !subItem.foodTime &&
                      !subItem.totalPrice
                  )?_c('span',{staticClass:"font-xl white"},[_vm._v(" 未清桌 ")]):_vm._e(),(subItem.totalPrice)?_c('span',{staticClass:"s4 font-xxxxl"},[_vm._v(" "+_vm._s(_vm._f("money")(subItem.totalPrice))+" ")]):_vm._e(),(subItem.foodTime)?_c('span',{staticClass:"s3 font-xl white"},[_vm._v(" "+_vm._s(_vm._f("date")(subItem.foodTime,"YYYY-MM-DD hh:mm"))+" ")]):_vm._e()],1)}),0)])}):_c('div',{staticClass:"text-c gray font-xxxxl"},[_c('a-icon',{attrs:{"type":"question-circle","theme":"filled"}}),_c('p',[_vm._v("本区域尚未添加桌台")])],1)],2),(_vm.menu.length > 1)?_c('div',{staticClass:"right text-c bd round flex-shrink"},[_c('ul',_vm._l((_vm.menu),function(item,index){return _c('li',{key:index,staticClass:"primary",class:{ cur: _vm.curIndex == index },on:{"click":function($event){return _vm.menuClick(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]):_vm._e()]),_c('div',{staticClass:"footer"},[_c('member-menu',{attrs:{"vip":'appCateringIndexVip'},on:{"click":function($event){_vm.showMember = true}}}),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('appCateringIndexNumberqueuing'),expression:"'appCateringIndexNumberqueuing'"}],staticClass:"menu",on:{"click":_vm.takeNumber}},[_vm._v(" 取号排队 "),(_vm.shortcutKey)?_c('span',{staticClass:"f-key"},[_vm._v("(F2)")]):_vm._e()]),_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('appCateringIndexCall'),expression:"'appCateringIndexCall'"}],staticClass:"menu",on:{"click":_vm.callNumber}},[_vm._v(" 叫号 "),(_vm.shortcutKey)?_c('span',{staticClass:"f-key"},[_vm._v("(F3)")]):_vm._e()])],1)]),_c('member-detail',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMember),expression:"showMember"}],staticClass:"flex-grow",attrs:{"format":"lease"},on:{"close":function($event){_vm.showMember = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }