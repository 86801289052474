<!--
  @name: 餐饮首页
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <!--三级路由-->
  <!-- <transition :name="slideName"> -->
  <router-view class="flex-grow" />
  <!-- </transition> -->
</template>

<script>
export default {
  name: "CateringIndex",
  data() {
    return {
      slideName: "slide-fade"
    };
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
/*.slide-fade-enter-active {*/
/*  transition: all 0.6s;*/
/*}*/
/*.slide-fade-leave-active {*/
/*  transition: all 0.3s;*/
/*  opacity: 0;*/
/*}*/
/*.slide-fade-enter,*/
/*.slide-fade-leave-to {*/
/*  transform: translateX(100%);*/
/*  opacity: 0;*/
/*}*/
/*// 处理留白*/
/*.ro-view {*/
/*  position: absolute;*/
/*  left: 0;*/
/*  right: 0;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*}*/
</style>
