<!--
  @name: 窗口餐饮，点菜列表部分
  @author: zhengyao
  @date:2021-09-09
-->
<template>
  <div>
    <div v-show="!showMember" class="flex-start flex-grow">
      <div class="wrapper flex-between flex-col relative flex-grow">
        <div class="header">
          <a-row type="flex" justify="start" align="middle">
            <a-col :lg="24">
              <a-form-model ref="form" :model="form" layout="inline">
                <a-form-model-item>
                  <a-icon
                    v-show="tableSpuId"
                    @click="back"
                    type="left-circle"
                    class="font-xxxxl primary pointer"
                  />
                </a-form-model-item>
                <a-form-model-item label="菜品分类" class="ml-x">
                  <a-cascader
                    :options="CategoryList"
                    :field-names="fieldNames"
                    placeholder="选择菜品"
                    @change="cascaderChange"
                  />
                </a-form-model-item>
                <a-form-model-item label="可售状态" class="ml-x">
                  <a-select
                    v-model="form.status"
                    allowClear
                    placeholder="选择可售状态"
                    style="width: 180px"
                    @change="getMenuList"
                  >
                    <a-select-option :key="1" value="">全部</a-select-option>
                    <a-select-option :key="2" :value="1">
                      可售
                    </a-select-option>
                    <a-select-option :key="3" :value="0"
                      >不可售</a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="菜品" class="ml-x">
                  <a-input-search
                    style="width: 260px"
                    v-model="form.keyword"
                    placeholder="输入菜品名称/编号搜索"
                    @search="getMenuList"
                    v-inputfocus
                    enter-button
                    allowClear
                  />
                </a-form-model-item>
              </a-form-model>
            </a-col>
          </a-row>
        </div>
        <a-empty description="暂无数据" v-if="!menuList.length" />
        <div class="main flex-grow over-auto relative" v-if="menuList.length">
          <a-row :gutter="[16, 16]">
            <a-col
              :xxl="8"
              :xl="12"
              :lg="24"
              v-for="item in menuList"
              :key="item.id"
            >
              <div class="ticket-card" @click="shoppingCartAdd(item)">
                <!-- 暂无图片 -->
                <img
                  alt="图片"
                  class="image"
                  :src="
                    item.skuImages ||
                      'https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16312/1631236535041.png'
                  "
                />
                <div class="flex-between flex-col flex-grow ml-2x">
                  <div class="title text-1">{{ item.name }}</div>
                  <div class="text-1" v-html="item.introduction"></div>
                  <div class="flex-start align-center">
                    <p class="flex-grow font-l money price ">
                      {{ item.sellingPrice | money }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="absolute" @click="onProductSaleabl(item)">
                <a-button
                  v-if="item.whetherSaleable"
                  shape="round"
                  type="primary"
                  >下架</a-button
                >
                <a-button v-else shape="round">上架</a-button>
              </div>
            </a-col>
          </a-row>
        </div>
        <footer class="footer">
          <member-menu
            :vip="'appCateringMenuListVip'"
            @click="showMember = true"
          ></member-menu>
        </footer>
      </div>
      <!-- 购物车 -->
      <shopping-cart ref="shoppingCart"></shopping-cart>
    </div>
    <!-- 选择规格 -->
    <a-modal
      :maskClosable="false"
      centered
      title="选择规格"
      v-model="showSpecifications"
    >
      <div class="amount">
        <a-form-model
          :model="ruleForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          v-if="updates"
        >
          <a-form-model-item
            v-for="(list, i) in foodSpecInfoList"
            :key="i"
            :label="list.spec"
          >
            <a-button
              class="mr-1x defaultbtn"
              button-style="solid"
              v-for="item in list.valueList"
              :class="{ check: item.checked }"
              :key="item.specValue"
              @click="onPractice(list, item)"
            >
              {{ item.specValue }}
            </a-button>
            <div class="red error">
              {{ list.error }}
            </div>
          </a-form-model-item>
          <a-form-model-item label="备注">
            <a-textarea
              allowClear
              :maxLength="50"
              placeholder="最多输入50字符"
              v-model="ruleForm.remark"
              type="textarea"
              :rows="4"
            />
          </a-form-model-item>
        </a-form-model>
      </div>
      <div slot="footer" class="text-c">
        <a-button @click="onCancel('showSpecifications')">取消</a-button>
        <a-button type="primary" @click="handleOk">确定</a-button>
      </div>
    </a-modal>
    <!-- 选择套餐 -->
    <a-modal
      :maskClosable="false"
      :centered="true"
      title="选择套餐"
      v-model="showPackage"
      :width="900"
    >
      <div class="flex-start">
        <ul class="package-ul">
          <li
            v-for="(menu, i) in packageList"
            :key="i"
            @click="onMenu(menu)"
            class="pointer text-c text-1"
            :class="{ menuCheck: menu.checked }"
          >
            {{ menu.name }}
          </li>
        </ul>
        <div class="package-check res">
          <a-row :gutter="[10, 10]">
            <a-col
              :xl="6"
              :lg="8"
              :md="12"
              v-for="item in packageItemList.mealDishesVOList"
              :key="item.id"
            >
              <div class="packge pointer" @click="onPackage(item)">
                <p class="font-l">{{ item.name }}</p>
                <p class="price">+ {{ item.sellingPrice | money }}</p>
                <img
                  :src="
                    item.checked
                      ? require('../../assets/images/member-checked.png')
                      : ''
                  "
                  width="40"
                  alt=""
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div slot="footer" class="text-c">
        <a-button @click="onCancel('showPackage')">取消</a-button>
        <a-button type="primary" @click="packageConfirm">确定</a-button>
      </div>
    </a-modal>
    <!-- 上架下架 -->
    <a-modal
      :maskClosable="false"
      :centered="true"
      title="提示"
      v-model="showState"
      :width="400"
    >
      <div class="text-c m-3x">是否确定将所选菜品下架？</div>
      <div slot="footer" class="text-c">
        <a-button @click="showState = false">取消</a-button>
        <a-button type="primary" @click="showState = false">确定</a-button>
      </div>
    </a-modal>
    <!-- 会员详情 -->
    <member-detail
      class="flex-grow"
      v-show="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>
  </div>
</template>

<script>
import moment from "moment";
import {
  productFrontFastorderfood,
  productFrontDishesspecification,
  productFrontMealdishes
} from "@/api/search";

import ShoppingCart from "./ShoppingCart";
import { productMerchantCategoryList, productSaleabl } from "@/api/product";
import { foodCart, moreProduct, reselectSet } from "@/api/cart";
import MemberMenu from "../../components/member/MemberMenu.vue";
import MemberDetail from "../home/member/MemberDetail";
import { mapActions } from "vuex";

export default {
  name: "MenuList",
  components: { ShoppingCart, MemberMenu, MemberDetail },
  data() {
    return {
      tableSpuId: this.$route.query.spuId, //桌台id
      showMember: false,
      updates: true, //重新渲染规格
      showSpecifications: false, //规格弹窗
      showPackage: false, //选择套餐弹窗
      showState: false, //上下架状态
      checkedCart: {}, //当前选中的菜品或套餐
      foodSetSubProduct: [], //购物车套餐回显已组装数据
      products: {}, //购物车套餐回显总数据
      foodSpecInfoList: [], //规格列表
      specifications: [], //已选规格
      SpecificationProperties: [], //规格匹配id
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      ruleForm: {
        //选择规格form
        remark: ""
      },
      CategoryList: [], //菜品分类列表
      packageList: [], //套餐菜单列表
      packageItemList: {}, //当前选中的套餐分组
      moment, // 初始化moment
      form: {
        // playDate: moment().valueOf(), // 游玩日期
        categoryId: "", //菜品分类
        status: 1, //可售状态 初始默认为 可售
        keyword: "" //菜品名称/菜品编号
      },
      menuList: [], // 菜品列表
      fieldNames: {
        //选择菜品自定义字段
        label: "name",
        value: "id",
        children: "children"
      }
    };
  },
  created() {
    this.getCategoryList();
    this.getMenuList();
    if (!this.tableSpuId) {
      this.setEndOfPayConfig({
        backUrl: "/catering/menuList",
        logoutMember: true
      });
    }
  },
  methods: {
    ...mapActions("order", ["setEndOfPayConfig"]),
    //查询套餐
    getPackage(spuId, row = {}) {
      productFrontMealdishes({
        spuId,
        tableSpuId: this.tableSpuId ? this.tableSpuId : null
      })
        .then(res => {
          //默认checked
          res.forEach(item => {
            item.checked = false;
            if (item.mealDishesVOList && item.mealDishesVOList.length) {
              item.mealDishesVOList.forEach(list => {
                list.checked = false;
                //默认选中
                if (list.whetherDefault) {
                  list.checked = true;
                }
              });
            }
          });
          //加购套餐
          if (Object.keys(row).length) {
            //套餐回显
            res.forEach(item => {
              if (item.name == row.groupName) {
                item.checked = true;
                this.packageItemList = item;
              }
            });
            if (this.foodSetSubProduct.length) {
              this.foodSetSubProduct.forEach(food => {
                res.forEach(pack => {
                  if (food.groupName == pack.name) {
                    food.list.forEach(foo => {
                      pack.mealDishesVOList.forEach(packs => {
                        if (foo.productId == packs.id) {
                          packs.checked = true;
                        }
                      });
                    });
                  }
                });
              });
            }
          } else {
            res[0].checked = true;
            this.packageItemList = res[0];
          }
          this.packageList = res;
          this.showPackage = true;
        })
        .catch(() => {});
    },
    //选择套餐左侧分组
    onMenu(item) {
      this.packageItemList = item;
      this.packageList.forEach(element => {
        element.checked = false;
      });
      item.checked = true;
    },
    //选择套餐右侧菜品
    onPackage(item) {
      let { sellMost, name, mealDishesVOList } = this.packageItemList;
      let checkLength = mealDishesVOList.filter(items => items.checked).length;
      if (checkLength >= sellMost) {
        //大于设置的最多需选
        if (!item.checked) {
          this.$message.warning(name + "最多可选" + sellMost + "个选项");
          return false;
        }
      }
      item.checked = !item.checked;
    },
    //加购套餐
    additionalPurchase(data) {
      reselectSet(data)
        .then(res => {
          if (Object.keys(res).length) {
            this.$refs.shoppingCart.getFoodCartDetail();
          }
        })
        .catch(() => {});
    },
    //套餐确定
    packageConfirm() {
      let { id, spuId } = this.checkedCart;
      let ids = [];
      let adopt = true;
      let data = {
        productId: id,
        spuId: spuId,
        specOrSet: ids,
        tableSpuId: this.tableSpuId ? this.tableSpuId : null
      };
      this.packageList.forEach(list => {
        let { sellLeast, sellMost } = list;
        let checkLength = list.mealDishesVOList.filter(item => item.checked)
          .length;
        if (checkLength < sellLeast) {
          //小于设置的最少需选
          adopt = false;
          this.$message.warning(list.name + "最少需选" + sellLeast + "个选项");
        } else if (checkLength > sellMost) {
          //大于设置的最多需选
          adopt = false;
          this.$message.warning(list.name + "最多可选" + sellMost + "个选项");
        }
        list.mealDishesVOList.filter(list => {
          if (list.checked) {
            ids.push({
              productId: list.id,
              spuId: list.spuId
            });
          }
        });
      });
      if (adopt) {
        if (this.foodSetSubProduct.length) {
          //加购套餐
          let { productId, spuId, specialId } = this.products;
          data.id = sessionStorage.getItem("cateringCartId");
          data.productId = productId;
          data.spuId = spuId;
          data.specialId = specialId;
          this.additionalPurchase(data);
        } else {
          this.cartAddConFirm(data);
        }

        this.showPackage = false;
      }
    },
    //查询规格匹配规则
    getSpecifications(data) {
      productFrontDishesspecification(data)
        .then(res => {
          this.SpecificationProperties = res;
        })
        .catch(() => {});
    },
    //选择规格
    onPractice(list, row) {
      list.error = ""; //清空校验
      let { selectMost } = list;
      let checkLength = list.valueList.filter(item => item.checked).length;
      if (checkLength >= selectMost) {
        //大于设置的最多需选
        if (!row.checked) {
          this.$message.warning(list.spec + "最多可选" + selectMost + "个选项");
          return false;
        }
      }
      row.checked = !row.checked;
      let array = [];
      this.foodSpecInfoList.forEach(item => {
        item.valueList.filter(list => {
          if (list.checked) {
            array.push(list);
          }
        });
      });
      this.specifications = array;
    },
    //规格确定
    handleOk() {
      let adopt = true;
      this.foodSpecInfoList.forEach(list => {
        let { selectLeast, selectMost } = list;
        let checkLength = list.valueList.filter(item => item.checked).length;
        if (checkLength < selectLeast) {
          //小于设置的最少需选
          adopt = false;
          list.error = list.spec + "最少需选" + selectLeast + "个选项";
        } else if (checkLength > selectMost) {
          //大于设置的最多需选
          adopt = false;
          list.error = list.spec + "最多需选" + selectMost + "个选项";
        } else {
          list.error = "";
        }
      });
      if (this.ruleForm.remark.length > 50) {
        adopt = false;
        return this.$message.warning("备注最多输入50个文字");
      }
      this.updates = false;
      this.$nextTick(function() {
        this.updates = true;
      });
      if (adopt) {
        let { id, spuId } = this.checkedCart;
        let specOrSetArray = [];
        this.specifications.forEach(item => {
          this.SpecificationProperties.forEach(list => {
            if (item.indexes == list.indexes) {
              // if (item.id == list.id) {
              specOrSetArray.push({
                productId: list.id,
                spuId: list.spuId
              });
            }
          });
        });
        let data = {
          productId: id,
          spuId: spuId,
          specOrSet: specOrSetArray,
          remark: this.ruleForm.remark,
          tableSpuId: this.tableSpuId ? this.tableSpuId : null
        };
        this.cartAddConFirm(data);
        this.showSpecifications = false;
      }
    },
    //上架下架
    onProductSaleabl(item) {
      let data = {
        id: item.spuId,
        whetherSaleable: item.whetherSaleable ? 0 : 1,
        statusChangeRemark: "",
        tableSpuId: this.tableSpuId ? this.tableSpuId : null
      };
      productSaleabl(data)
        .then(() => {
          this.getMenuList();
        })
        .catch(() => {});
    },
    //菜品列表
    getMenuList() {
      productFrontFastorderfood({
        page: 1,
        size: 500,
        tableSpuId: this.tableSpuId ? this.tableSpuId : null,
        ...this.form
      })
        .then(res => {
          if (res.records && res.records.length) {
            //7-6 暂时隐藏时段判断，接口处理
            // res.records.forEach((item, i) => {
            //   item.introduction = item.spuSpecInfo.introduction;
            //   //限制销售时段超过时段不显示
            //   if (item.spuSpecInfo.saleDateType === "1") {
            //     let time = JSON.parse(item.spuSpecInfo.saleTimeRange),
            //       endDate = new Date(time[1]).getTime(),
            //       date = new Date().getTime();
            //     if (date > endDate) {
            //       res.records.splice(i, 1);
            //     }
            //   }
            // });
            this.menuList = res.records;
          } else {
            this.menuList = [];
          }
        })
        .catch(() => {});
    },
    //菜品分类
    getCategoryList() {
      productMerchantCategoryList({
        type: 12,
        tableSpuId: this.tableSpuId ? this.tableSpuId : null
      })
        .then(res => {
          const newArray = [];
          const len = res.length;
          for (let i = 0; i < len; i++) {
            if (res[i].parentId == 0) {
              res[i].customRootIndex = 1; // 根数据-索引 （一级分类）
              this.toTree(res[i], res, 2); // 二级索引
              newArray.push(res[i]);
            }
          }
          this.CategoryList = newArray;
        })
        .catch(() => {});
    },
    //菜品分类change
    cascaderChange(val) {
      if (val.length) {
        this.form.categoryId = val[val.length - 1];
      } else {
        this.form.categoryId = "";
      }
      this.getMenuList();
    },
    //弹窗取消
    onCancel(type) {
      this[type] = false;
    },
    //把后台数据，转换成树形结构
    toTree(parent, list, index) {
      const child = [];
      for (let i = 0; i < list.length; i++) {
        if (parent.id == list[i].parentId) {
          list[i].customRootIndex = index; // 二级分类（索引）
          child.push(list[i]);
          this.toTree(list[i], list, 3); // 三级分类（索引）
        }
      }
      parent.children = child;
    },
    //查询id和spuId
    queryId(array, children) {
      let id = [];
      array.forEach(item => {
        item[children].filter(list => {
          if (list.checked) {
            id.push(list);
          }
        });
      });
      return id;
    },
    //加入购物车判断
    shoppingCartAdd(item) {
      let {
        combo,
        spuSpecInfo: { whetherMultiSpec }
      } = item;
      let data = {
        productId: item.id,
        spuId: item.spuId,
        tableSpuId: this.tableSpuId ? this.tableSpuId : null
      };
      let { buyLimitType, buyMost } = item.spuSpecInfo;
      //记录当前选中的菜品或套餐
      this.checkedCart = item;
      //每单购买数量限制
      let { cartList } = this.$refs.shoppingCart;
      let current =
        cartList.productItemList &&
        cartList.productItemList.filter(list => list.productId == item.id);
      let buyNums =
        current && current.reduce((num, li) => (num += li.buyNum), 0);
      if (buyLimitType === "1") {
        if (buyNums >= buyMost) {
          return this.$message.success(
            item.name + " 最多可购买 " + buyMost + " 件"
          );
        }
      }

      if (combo) {
        //为1，选了套餐
        this.getPackage(item.spuId);
      } else if (whetherMultiSpec == "1") {
        //选了菜品有规格
        let foodSpecInfo = JSON.parse(item.spuSpecInfo.foodSpecInfo);
        this.ruleForm.remark = "";
        this.showSpecifications = true;
        this.specifications = [];
        foodSpecInfo.forEach((item, i) => {
          let selectMost = item.selectMost;
          let n = 0;
          item.valueList.forEach((list, index) => {
            list.checked = false;
            list.indexes = i + "-" + index;
            //默认项选中,如果默认数量大于设置的最多选择数量，则前面的选中
            if (list.whetherDefault) {
              n++;
              if (n <= selectMost) {
                list.checked = true;
              }
            }
            //赋值已选规格数组
            if (list.checked) {
              this.specifications.push(list);
            }
          });
        });
        this.foodSpecInfoList = foodSpecInfo;
        this.getSpecifications({
          spuId: item.spuId,
          tableSpuId: this.tableSpuId ? this.tableSpuId : null
        });
      } else if (whetherMultiSpec == "0") {
        //选了菜品无规格
        this.cartAddConFirm(data);
        // this.$message.success("选择了菜品无规格");
      }
    },
    //加入购物车确认
    cartAddConFirm(data) {
      let cateringCartId = sessionStorage.getItem("cateringCartId"); //购物车id
      //更新购物车
      if (cateringCartId) {
        data["id"] = cateringCartId;
        moreProduct(data)
          .then(() => {
            this.$refs.shoppingCart.getFoodCartDetail();
          })
          .catch(() => {});
      } else {
        //新增购物车
        foodCart(data)
          .then(res => {
            if (Object.keys(res).length) {
              sessionStorage.setItem("cateringCartId", res.id);
              this.$refs.shoppingCart.getFoodCartDetail();
            }
          })
          .catch(() => {});
      }
    },
    //返回桌台页
    back() {
      this.$router.go(-1);
      this.$store.commit("common/setCurrentLeftMenuKey", "catering:index");
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/custom.less";

//选择规格
.amount {
  .error {
    height: 10px;
  }
  .defaultbtn {
    border: 1px solid #45b1fb;
    color: #45b1fb;
  }
  .check {
    background: #4182fc;
    color: white;
  }
}
//套餐左侧菜单
.package-ul {
  border: 1px solid deepskyblue;
  border-radius: 5px;
  margin-right: 15px;
  height: max-content;
  li {
    border-bottom: 1px solid #45b1fb;
    width: 150px;
    padding: 10px;
    color: #45b1fb;
    .left {
      padding: 10px 18px;
    }
  }
  li:last-child {
    border: none;
  }
  .menuCheck {
    background: #45b1fb;
    color: white;
  }
}
//套餐右侧选项
.package-check {
  background: #f7f7f7;
  padding: 10px;
  border-radius: 5px;
  .packge {
    width: 150px;
    height: 120px;
    border-radius: 7px;
    background-color: #e4f6fc;
    position: relative;

    p {
      padding: 10px 0 0 15px;
    }
    p:nth-child(2) {
      margin-top: 25px;
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
.wrapper {
  margin-top: 16px;
  .over-auto {
    overflow: auto;
  }
  /*头部*/
  .header {
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 10px;
    border-radius: 5px;
  }
  /*中部*/
  .main {
    padding: 16px;
    margin-top: 16px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 5px;
    .ticket-card {
      display: flex;
      height: 130px;
      box-sizing: border-box;
      background: #e4f6fc;
      padding: 12px;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      &:hover,
      &:active {
        box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
      }
      .image {
        width: 120px;
        border-radius: 4px;
        flex-shrink: 0; // 防止图片被压缩
      }

      .title {
        font-size: 18px;
      }
      .text-1 {
        width: fit-content;
      }
      .money {
        font-size: 22px;
      }
      .ant-tag {
        border: none;
      }
    }
    .absolute {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }
  /*底部*/
  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    .menu {
      position: relative;
      width: 13%;
      padding: 8px 0;
      border: 1px solid @primary;
      text-align: center;
      color: @primary;
      border-radius: 20px;
      cursor: pointer;
      margin-right: 10px;
      &.action {
        color: #ffffff;
        background-color: @primary;
      }
      &.disabled {
        border-color: @disabled;
        color: @disabled;
        cursor: not-allowed;
        background: #f5f5f5;
      }
      .iconfont {
        font-size: 22px;
        position: absolute;
        top: -15px;
        right: -13px;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .f-key {
    display: none;
  }
}
</style>
